<template>
  <div id="classify-management">
    <MailboxState :state="MailboxStates" />
    <BatchDeleteMailbox :batchDeleteId="multipleSelection" :deleteMuch="deleteMuchMsg" />
    <el-button type="info" icon="el-icon-s-tools" @click="reset">重置</el-button>
    <SearchMailbox :inputButton="SearchMsg" />
    <SearchClassifyTime :searchTime="SearchClassTime" />
    <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="touristName" label="发件人" width="100"> </el-table-column>
      <el-table-column prop="messageType" label="类别名称" width="100">
        <template slot-scope="scope">{{ chinese(scope.row.messageType) }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间" width="120">
        <template slot-scope="scope">{{ getTime(scope.row.createTime) }}</template>
      </el-table-column>
      <el-table-column prop="messageTitle" label="信件标题" width="120">
        <template slot-scope="scope">
          <div :title="scope.row.messageTitle">
            {{ chinese(scope.row.messageTitle) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="messageContent" label="信件内容" width="170">
        <template slot-scope="scope">
          <div :title="scope.row.messageContent">
            {{ chinese(scope.row.messageContent, 12) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="isOpen" label="是否公开" width="80">
        <template slot-scope="scope">
          <el-switch
            :value="isPublicEry(scope.row.isOpen)"
            @change="changePublic(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="isReply" label="是否回复" width="80">
        <template slot-scope="scope">
          <el-switch :value="isReply(scope.row.isReply)" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="250">
        <template slot-scope="scope">
          <DeleteMailbox
            :index="tableData.length"
            :deleteChangeMsg="scope.row"
            :deleteOne="deleteOneMsg" />
          <ViweReplay :modifychangeMsg="scope.row" :replayOne="replayOneMsg" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :layout="layout"
      :total="total"
      :page-sizes="page_sizes"
      :page-size="page_size"
      :current-page="currentPage">
    </el-pagination>
  </div>
</template>

<script>
import {pages} from '../../mixins/pages';
import {
  findAllMessages,
  selectByContent,
  selectByEmail,
  selectByName,
  selectByPhone,
  findUnSeeState,
  findSuchMessages,
  openMessage,
  closeMessage
  // findOpenMessage,
  // findNotOpenMessage
} from '../../service/getData';
import DeleteMailbox from '../../pages/MailboxManagement/DeleteMailbox.vue';
import ViweReplay from '../../pages/MailboxManagement/ViweReplay.vue';
import BatchDeleteMailbox from '../../pages/MailboxManagement/BatchDeleteMailbox.vue';
import SearchMailbox from '../../pages/MailboxManagement/SearchMailbox.vue';
import MailboxState from '../../pages/MailboxManagement/MailboxState.vue';
import SearchClassifyTime from '../../pages/MailboxManagement/SearchClassifyTime.vue';
export default {
  name: 'MailboxManagement',
  mixins: [pages],
  data() {
    return {
      multipleSelection: [], // 多选框哪几个被选中
      tableData: [], // 分页遍历数据
      input: '', //输入框
      index: '', //搜索的类型 0：内容，1：邮箱，2：姓名，3：电话
      stateMsg: '-1', //不同的状态
      time: [], //时间搜索
      type: '', //类别搜索
      test: true,
      isPublic: false
    };
  },
  computed: {
    getTime() {
      //时间截取
      return (time) => {
        return time.split('T')[0];
      };
    },
    chinese() {
      //文字截取
      return (chinese, index = 5) => {
        if (chinese.length <= index) {
          return chinese;
        } else {
          return chinese.substring(0, index - 2) + '...';
        }
      };
    },
    isReply() {
      //是否回复状态
      return (state) => {
        return state === 1;
      };
    },
    isPublicEry() {
      //是否回复状态
      return (state) => {
        return state === 1;
      };
    }
  },
  components: {
    DeleteMailbox,
    ViweReplay,
    BatchDeleteMailbox,
    SearchMailbox,
    MailboxState,
    SearchClassifyTime
  },
  watch: {
    page_size_val() {
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    currentPage_val() {
      this._findAllType(this.currentPage_val, this.page_size_val);
    }
  },
  mounted() {
    // 第一次遍历信息
    this._findAllType();
  },
  methods: {
    changePublic(rowData) {
      //弹窗状态 0：未公开  1：公开状态
      //切换公开状态时出一个弹窗
      // rowData.isOpen = 0;
      console.log(rowData);
      let title = `此操作将${!rowData.isOpen ? '' : '取消'}公开信件：${
        rowData.messageContent
      } 是否继续？`;
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let isOpen = rowData.isOpen;
          // //公开信件
          if (rowData.isOpen === 0) {
            openMessage(rowData.messageId)
              .then((data) => {
                console.log(data);
                if (data.code === 200) {
                  rowData.isOpen = 1;
                  this.$message({
                    type: 'success',
                    message: '公开信件成功!'
                  });
                  if (this.stateMsg === '-1') {
                    //查看所有的信件
                    findAllMessages(this.currentPage_val, this.page_size_val).then((values) => {
                      console.log(values);
                      if (values.code === 200) {
                        this.total = values.page.pageSizeTotal;
                        this.tableData = values.object;
                      }
                    });
                  } else {
                    //查看其他状态下的信件 因为涉及到最后一页最后一条所以要判断数据
                    findUnSeeState(this.currentPage_val, this.page_size_val, this.stateMsg).then(
                      (values) => {
                        this.index = '';
                        this.input = '';
                        if (!values.object.list.length) {
                          // this.total = 0;
                          // this.tableData = [];
                          this.currentPage_val -= 1;
                          console.log(this.currentPage_val);
                          findUnSeeState(
                            this.currentPage_val,
                            this.page_size_val,
                            this.stateMsg
                          ).then((values) => {
                            this.index = '';
                            this.input = '';
                            this.total = values.object.total;
                            this.tableData = values.object.list;
                          });
                        } else {
                          this.total = values.object.total;
                          this.tableData = values.object.list;
                        }
                        console.log('values=', values);
                      }
                    );
                  }
                } else {
                  this.$message({
                    message: `${data.message}`
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            //取消公开信件
            closeMessage(rowData.messageId)
              .then((data) => {
                if (data.code === 200) {
                  rowData.isOpen = 0;
                  this.$message({
                    type: 'success',
                    message: '取消公开信件成功!'
                  });
                  console.log(this.currentPage_val, this.page_size_val);
                  if (this.stateMsg === '-1') {
                    findAllMessages(this.currentPage_val, this.page_size_val).then((values) => {
                      console.log(values);
                      if (values.code === 200) {
                        this.total = values.page.pageSizeTotal;
                        this.tableData = values.object;
                      }
                    });
                  } else {
                    findUnSeeState(this.currentPage_val, this.page_size_val, this.stateMsg).then(
                      (values) => {
                        this.index = '';
                        this.input = '';
                        if (!values.object.list.length) {
                          // this.total = 0;
                          // this.tableData = [];
                          this.currentPage_val -= 1;
                          findUnSeeState(
                            this.currentPage_val,
                            this.page_size_val,
                            this.stateMsg
                          ).then((values) => {
                            this.index = '';
                            this.input = '';
                            this.total = values.object.total;
                            this.tableData = values.object.list;
                          });
                        } else {
                          this.total = values.object.total;
                          this.tableData = values.object.list;
                        }
                        console.log('values=', values);
                      }
                    );
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          //根据现有公开状态修改
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改公开状态'
          });
        });
    },
    cancelIndex() {
      this.index = '';
      this.stateMsg = '-1';
      this.input = '';
      this.time = '';
      this.type = '';
    },
    MailboxStates(index) {
      //接收子组件传递的状态
      //取消搜索的值
      this.cancelIndex();
      this.stateMsg = index;
      switch (index) {
        case '-1':
          this._findAllType(1, this.page_size_val);
          break;
        default:
          this.index = '';
          this.input = '';
          this._findAllType(1, this.page_size_val);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteOneMsg() {
      //后台单个删除邮件后重新遍历
      if (this.tableData.length === 1 && this.currentPage_val !== 1) {
        this._findAllType(this.currentPage_val - 1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
    },
    deleteMuchMsg() {
      //后台批量删除邮件后重新遍历
      if (this.tableData.length === this.multipleSelection.length && this.currentPage_val !== 1) {
        this._findAllType(this.currentPage_val - 1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
      this.multipleSelection = []; //讲批量选择清空
    },
    replayOneMsg() {
      //回复成功后重新遍历
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    SearchMsg(values, index) {
      //搜索
      this.cancelIndex();
      this.input = values;
      this.index = index;
      this._findAllType(1, this.page_size_val);
    },
    SearchClassTime(type, time) {
      //根据时间和类别搜索
      console.log(time);
      this.cancelIndex();
      this.type = type;
      this.time = time;
      this._findAllType(1, this.page_size_val);
    },
    reset() {
      //重置
      this.cancelIndex();
      this._findAllType(1, this.page_size_val);
    },
    _mailboxState(pageNow, pageSize) {
      findUnSeeState(pageNow, pageSize, this.stateMsg).then((values) => {
        this.index = '';
        this.input = '';
        console.log(values.object === null);
        if (values.object === null) {
          this.total = 0;
          this.tableData = [];
        } else {
          this.total = values.page.pageSizeTotal;
          this.tableData = values.object;
        }
        console.log('values=', values);
      });
    },
    _findAllType(pageNow, pageSize) {
      //后台遍历邮件，如果this.input有内容则模糊查询，如果没有内容则正常分页
      console.log(this.time);
      if (
        this.input.length === 0 &&
        this.stateMsg === '-1' &&
        this.time.length === 0 &&
        this.type === ''
      ) {
        //正常遍历数据
        console.log('正常遍历');
        findAllMessages(pageNow, pageSize).then((values) => {
          console.log(values);
          if (values.code === 200) {
            this.total = values.page.pageSizeTotal;
            this.tableData = values.object;
          }
        });
      } else if (
        this.input !== 0 &&
        this.stateMsg === '-1' &&
        this.time.length === 0 &&
        this.type === ''
      ) {
        //this.input里面有内容，说明进行了搜索，判断是搜索的类型
        console.log('遍历搜索内容');
        let input = this.input;
        switch (this.index) {
          case '0': //根据内容进行搜索
            selectByContent(input, pageNow, pageSize).then((values) => {
              console.log('0', values);
              console.log(this);
              if (values.object === null) {
                this.total = 0;
                this.tableData = [];
              } else {
                this.total = values.page.pageSizeTotal;
                this.tableData = values.object;
              }
            });
            break;
          case '1': //根据邮箱进行搜索
            selectByEmail(input, pageNow, pageSize).then((values) => {
              console.log('1', values);
              if (values.object === null) {
                this.total = 0;
                this.tableData = [];
              } else {
                this.total = values.page.pageSizeTotal;
                this.tableData = values.object;
              }
            });
            break;
          case '2': //根据姓名进行搜索
            selectByName(input, pageNow, pageSize).then((values) => {
              console.log('2', values);
              if (values.object === null) {
                this.total = 0;
                this.tableData = [];
              } else {
                this.total = values.page.pageSizeTotal;
                this.tableData = values.object;
              }
            });
            break;
          case '3': //根据电话进行搜索
            selectByPhone(input, pageNow, pageSize).then((values) => {
              console.log('3', values);
              if (values.object === null) {
                this.total = 0;
                this.tableData = [];
              } else {
                this.total = values.page.pageSizeTotal;
                this.tableData = values.object;
              }
            });
        }
      } else if (this.time !== '' || this.type !== '') {
        console.log('遍历时间类别搜索');
        console.log(this.type === '');
        console.log(this.time === '');
        let startime = this.time.length === 0 ? undefined : this.time[0];
        let endtime = this.time.length === 0 ? undefined : this.time[1];
        findSuchMessages(endtime, pageNow, pageSize, startime, this.type).then((values) => {
          console.log(values);
          if (values.object === null) {
            this.total = 0;
            this.tableData = [];
          } else {
            this.total = values.page.pageSizeTotal;
            this.tableData = values.object;
          }
        });
      } else {
        //根据不同的类型进行遍历
        console.log('根据不同类型');
        console.log(pageNow);
        findUnSeeState(pageNow, pageSize, this.stateMsg).then((values) => {
          console.log('1', values);
          this.index = '';
          this.input = '';
          console.log(values.object === null);
          if (values.object === null) {
            this.total = 0;
            this.tableData = [];
          } else {
            this.total = values.object.total;
            this.tableData = values.object.list;
          }
          console.log('values=', values);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
