<template>
  <el-select class="states" v-model="value" placeholder="状态">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'MailboxState',
  props: ['state'],
  data() {
    return {
      options: [{
        value: '-1',
        label: '所有'
      }, {
        value: '0',
        label: '未查看'
      }, {
        value: '1',
        label: '已回复'
      }, {
        value: '2',
        label: '未回复'
      }, {
        value: '3',
        label: '已公开'
      }, {
        value: '4',
        label: '未公开'
      }],
      value: '-1'
    };
  },
  watch: {
    value(){
      this.state(this.value);
    }
  }
};
</script>

<style lang="scss">
.states {
  margin-right: 20px;
  width: 100px;
}
</style>