<template>
  <div id="search-classify">
    <el-select class="states" v-model="value" placeholder="状态">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
      <el-input :placeholder="placeholder()" v-model="input" clearable style="width: 300px;"> </el-input>
      <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
  </div>
</template>

<script>
export default {
  name: 'SearchClassify',
  props: ['inputButton'],
  data() {
    return {
      input: '',
      options: [{
        value: '0',
        label: '内容'
      }, {
        value: '1',
        label: '邮箱'
      }, {
        value: '2',
        label: '姓名'
      }, {
        value: '3',
        label: '电话'
      }],
      value: '0'
    };
  },
  computed: {
    placeholder(){
      return function(){
        switch (this.value) {
          case '0':
            return '根据内容进行搜索';
          case '1':
            return '根据邮箱进行搜索';
          case '2':
            return '根据姓名进行搜索';
          case '3':
            return '根据电话进行搜索';
        }
      };
    }
  },
  methods: {
    search(){
      let message = '';
      let isComplance = false;
      let input = this.input;
      // const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;//验证邮箱
      // const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;//验证手机号
      switch (this.value){
        case '0':
          if (input.trim().length===0){
            message = '请输入内容';
          } else {
            isComplance = true;
          }
          break;
        case '1':
          if (input.trim().length===0){
            message = '请输入邮箱';
          } else {
            isComplance = true;
          }
          break;
        case '2':
          if (input.trim().length===0){
            message = '请输入正确的姓名';
          } else {
            isComplance = true;
          }
          break;
        case '3':
          if (input.trim().length===0){
            message = '请输入手机号码';
          } else {
            isComplance = true;
          }
      }
      if (isComplance){
        this.inputButton(this.input, this.value);
      } else {
        this.$message({
          type: 'error',
          message: message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#search-classify {
  margin-top: 20px;
}
</style>
