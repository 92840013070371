<template>
  <el-form ref="form" label-width="80px">
    <el-form-item label="用户名称">
      <el-input v-model="message.touristName" disabled class="my-input"></el-input>
    </el-form-item>
    <el-form-item label="类别">
      <el-input v-model="message.messageType" disabled></el-input>
    </el-form-item>
    <el-form-item label="电话">
      <el-input v-model="message.touristPhone" disabled></el-input>
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input v-model="message.touristEmail" disabled></el-input>
    </el-form-item>
    <el-form-item label="发布时间">
      <el-date-picker
        type="date"
        placeholder="选择日期"
        v-model="message.createTime"
        style="width: 100%;"
        disabled></el-date-picker>
    </el-form-item>
    <el-form-item label="是否回复">
      <el-switch v-model="isReplys" disabled></el-switch>
    </el-form-item>
    <el-form-item label="是否公开">
      <el-switch v-model="isOpen" disabled></el-switch>
    </el-form-item>
    <el-form-item label="信件标题">
      <el-input type="textarea" v-model="message.messageTitle" disabled></el-input>
    </el-form-item>
    <el-form-item label="信件内容">
      <el-input type="textarea" v-model="message.messageContent" disabled></el-input>
    </el-form-item>
    <el-form-item label="我的回复">
      <el-input v-if="nowReplay" type="textarea" v-model="nowReplay" disabled></el-input>
      <el-input v-else type="textarea" v-model="replay"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">回复</el-button>
      <el-button @click="cancelBtn">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {replyMessage, findReplyByMessageId} from '../../service/getData';
export default {
  name: 'ViewForm',
  props: ['cancel', 'modifychangeMsg', 'replays'],
  data() {
    return {
      message: '',
      replay: '',
      nowReplay: '',
      isReplys: false,
      isOpen: false
    };
  },
  mounted() {
    this.message = this.modifychangeMsg;
    console.log(this.message);
    this.isReplys = this.message.isReply === 1;
    this.isOpen = this.message.isOpen === 1;
    findReplyByMessageId(this.message.messageId).then((values) => {
      console.log(values);
      console.log(typeof values.object === 'object');
      if (typeof values.object === 'object') {
        this.nowReplay = values.object.replyContent;
      }
    });
  },
  // createTime: "2022-05-22T02:37:15.000+00:00"
  // isDelete: 0
  // isReply: 0
  // messageContent: "111"
  // messageId: 251
  // messageTitle: "111"
  // messageType: "生活"
  // touristEmail: null
  // touristName: null
  // touristPhone: null
  methods: {
    onSubmit() {
      if (this.replay.trim().length === 0) {
        this.$message({
          type: 'error',
          message: '请输入回复内容'
        });
      } else {
        replyMessage(this.message.messageId, this.replay).then((values) => {
          console.log(values);
          this.cancel();
          if (values.code === 200) {
            this.replays();
            this.$message({
              type: 'success',
              message: '回复成功'
            });
          } else {
            this.$message({
              type: 'error',
              message: values.message
            });
          }
        });
      }
    },
    cancelBtn() {
      this.cancel();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  color: #5c5b5b !important;
  font-size: 17px;
}
/deep/ .el-textarea__inner {
  color: #5c5b5b !important;
  font-size: 17px;
}
</style>