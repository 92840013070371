<template>
    <el-button type="danger" icon="el-icon-delete" @click="open">删除</el-button>
</template>

<script>
import {deleteMessages} from '../../service/getData';
export default {
  name: 'AddClassify',
  props: ['deleteChangeMsg', 'index', 'deleteOne'],
  data(){
    return {
      val: ''
    };
  },
  methods: {
    open() {
      this.$confirm(`确定删除${this.deleteChangeMsg.messageTitle}吗?`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.deleteChangeMsg);
        deleteMessages(this.deleteChangeMsg.messageId).then(values=>{
          if (values.code === 200){
            this.deleteOne();//返回父组件删除类别，父组件重新遍历
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>