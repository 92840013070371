<template>
  <div class="searchclassifytime">
      <el-select class="states" v-model="typeId" placeholder="类别">
      <el-option
        v-for="item in options"
        :key="item.typeId"
        :label="item.typeName"
        :value="item.typeName">
      </el-option>
    </el-select>
    <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
    </el-date-picker>
    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
  </div>
</template>

<script>
import {findAllTypes} from '../../service/getData';
export default {
  name: 'SearchClassifyTime',
  props: ['searchTime'],
  data() {
    return {
      input: '',
      options: [],
      typeId: '', //类别的id
      time: ''//时间
    };
  },
  mounted(){
    this._findAllTypes();
  },
  methods: {
    getData(n) {
      n = new Date(n);
      return n.toLocaleDateString().replace(/\//g, '-') + ' ' + n.toTimeString().substr(0, 8);
    },
    _findAllTypes(){
      findAllTypes().then(values=>{
        this.options = values.object;
      });
    },
    search(){
      let {typeId, time} = this;
      if (typeId===''&&time===''){
        this.$message({
          type: 'error',
          message: '请选择类别或时间'
        });
      } else {
        let arrTime = [];
        if (this.time.length!==0){
          const startTime = this.getData(this.time[0].getTime());
          const endTime = this.getData(this.time[1].getTime());
          arrTime = [startTime, endTime];
        }
        this.searchTime(typeId, arrTime);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.searchclassifytime {
  margin-top: 10px;
}
</style>